/*

This program is free software: you can redistribute it and/or modify it under 
the terms of the GNU General Public License as published by 
the Free Software Foundation, either version 3 of the License, 
or (at your option) any later version.


This program is distributed in the hope that it will be useful, 
but WITHOUT ANY WARRANTY; without even the implied warranty of 
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  
See the GNU General Public License for more details.


You should have received a copy of the GNU General Public License
 along with this program.  If not, see <https://www.gnu.org/licenses/>.

Copyright © 2019 Cloud Linux Software Inc.

This software is also available under ImunifyAV commercial license,
see <https://www.imunify360.com/legal/eula>
*/
import { LicenseInBackend } from './license';
import { EulaInBackend } from './eula';

export type ApiMethod<E, R> = (parameters: E | undefined) => RequestParams<R>;

export class RequestParams<R = any> {
    // https://www.typescriptlang.org/docs/handbook/declaration-files/do-s-and-don-ts.html#generics
    anchor?: R;
    constructor(public method: string[], public params: { [k: string]: any } = {}) {
    }

    toString() {
        return JSON.stringify(this);
    }
}

export class ExtendedRequestParams<R = any> extends RequestParams<R> {
    constructor(
        public command?: string,
        public method: string[] = [],
        public params: { [k: string]: any } = {},
    ) {
        super(method, params);
    }
}

export enum Result {
    ERROR = 'error',
    WARNING = 'warnings',
    SUCCESS = 'success',
}

export enum AgentStatus {
    OK = 'running',
    INSTALLING = 'installing',
    NOT_INSTALLED = 'not_installed',
    FAILED_TO_INSTALL = 'failed_to_install',
    STOPPED = 'stopped',
}

export interface AgentResponse<E = any> {
    data: E;
    result: Result;
    messages: string[] | string;
    status?: AgentStatus;
}

export interface NormalizedAgentResponse<E> {
    data: E;
    result: Result;
    messages: string[];
}

export type Subtract<U, V> = {
    [K in Exclude<keyof U, V>]: U[K];
};

export interface AgentResponseData<E> {
    items: E;
    version: string;
    strategy?: string;
    license: LicenseInBackend & {
        expiration?: any;
    };
    eula?: EulaInBackend;
}

export type ResponseDataExceptItems = Subtract<AgentResponseData<null>, 'items'>;

export type ResponseDataExceptItemsAndEula
    = Subtract<ResponseDataExceptItems, { eula: EulaInBackend }>;

export interface ListResponseData<E> extends AgentResponseData<E[]> {
    max_count?: number;
}

interface SucceededFailedResponseData extends ResponseDataExceptItems {
    succeeded: string[];
    failed: string[];
}

export type NumberAgentResponse = AgentResponse<AgentResponseData<number>>;
export type NoItemsAgentResponse = AgentResponse<ResponseDataExceptItems>;
export type NoItemsAndEulaAgentResponse = AgentResponse<ResponseDataExceptItemsAndEula>;
export type NullAgentResponse = AgentResponse<AgentResponseData<null>>;
export type StringAgentResponse = AgentResponse<AgentResponseData<string>>;
export type SucceededFailedAgentResponse = AgentResponse<SucceededFailedResponseData>;

export type DataFrom<T> = T extends AgentResponse<infer U> ? U : 'Wrong type';

export interface WindowInterface extends Window {
    i360Test: {
        type: string;
        disableNews: boolean;
        disableStats: boolean;
        lastRequest: CommandReport;
        requests: {};
        requestsHistory: CommandReport[];
        testMode: boolean | { [key: string]: any };
        mock: { [key: string]: any };
        jq: any;
    };
}

export interface CommandReport {
    source: string;
    data: Partial<ExtendedRequestParams> | FormData;
    request: {
        response: string,
        startDate: number,
        endDate: number,
    };
}
