/*

This program is free software: you can redistribute it and/or modify it under 
the terms of the GNU General Public License as published by 
the Free Software Foundation, either version 3 of the License, 
or (at your option) any later version.


This program is distributed in the hope that it will be useful, 
but WITHOUT ANY WARRANTY; without even the implied warranty of 
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  
See the GNU General Public License for more details.


You should have received a copy of the GNU General Public License
 along with this program.  If not, see <https://www.gnu.org/licenses/>.

Copyright © 2019 Cloud Linux Software Inc.

This software is also available under ImunifyAV commercial license,
see <https://www.imunify360.com/legal/eula>
*/
import { CommandReport, WindowInterface } from '@imunify360-api/misc';
import { MatIconRegistry } from '@angular/material/icon';
import { AppState } from 'app/core/app.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpEvent, HttpHandler, HttpInterceptor,
    HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Inject, Optional, SkipSelf } from '@angular/core';
import { Observable } from 'rxjs';
declare const window: WindowInterface;
declare const jQuery: any;
declare const Prototype: any;
jQuery.noConflict();

if (typeof Prototype !== 'undefined' && Prototype.BrowserFeatures.ElementExtensions) {
    let disablePrototypeJS = (method, pluginsToDisable) => {
            pluginsToDisable.each(function (plugin) {
                jQuery(window).on(method + '.bs.' + plugin, (event) => {
                    event.target[method] = undefined;
                    setTimeout(() => {
                        delete event.target[method];
                    }, 0);
                });
            });
        },
        pluginsToDisable = ['collapse', 'dropdown', 'modal', 'tooltip', 'popover', 'tab'];
    disablePrototypeJS('show', pluginsToDisable);
    disablePrototypeJS('hide', pluginsToDisable);
}



export function loadTestInterceptor() {
    window.i360Test = {
        type: 'e2e',
        disableNews: true,
        disableStats: true,
        testMode: {},
        lastRequest: {} as CommandReport,
        requests: {},
        requestsHistory: [],
        mock: [],
        jq: jQuery,
    };

    jQuery(function() {
        // remove header in plesk
        // it blocks clicks in navigation tests
        jQuery('.page-header-wrapper').css({visibility: 'hidden'});

        // remove breadcrumbs in cpanel
        // they block clicks to elements on the top of the screen
        jQuery('#navigation').css({visibility: 'hidden'});
    });
}

export function bootstrapApp(main: Function) {
    function __domReadyHandler() {
        document.removeEventListener('DOMContentLoaded', __domReadyHandler);
        main();
    }
    switch (document.readyState) {
        case 'loading':
            document.addEventListener('DOMContentLoaded', __domReadyHandler);
            break;
        case 'interactive':
        case 'complete':
        default:
            main();
    }
}

export function enableSvgIcons(iconRegistry: MatIconRegistry,
                               appState: AppState, sanitizer: DomSanitizer) {
    const icons = [
        'fish',
        'aquarium',
        'add-ip',
        'ignore-all',
        'broom',
        'bell',
        'atoms',
        'done',
        'chip',
        'update',
        'clock',
        'dmc',
        'full-access',
        'icon-exclamation',
        'logo-grey',
        'ellipsis',
        'shark',
        'logo-imunify',
        'new-features-management',
        'profile',
        'support',
        'logo-imunifyAV',
        'logo-imunifyAVPlus',
        'logo-imunifyAVPlus-grey',
        'key',
        'icon-question',
        'icon-delete',
        'icon-add',
        'icon-key',
        'icon-bell-in-circle',
        'icon-info',
        'dashboard-no-data-found',
        'read-more',
        'events-notifications',
        'check-circle',
        'shield',
        'undraw-server-down',
        'upgrade-by-key',
    ];
    for (const icon of icons) {
        iconRegistry.addSvgIcon(icon, sanitizer.bypassSecurityTrustResourceUrl(
            `${appState.imagePath}/${icon}.svg`,
        ));
    }
}

export class AngularIssues18894Crutch implements HttpInterceptor {
    constructor(private interceptors: HttpInterceptor[]) {}
    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
        return (this.interceptors || []).reduceRight(
            (next, interceptor) => ({
                // HttpInterceptorHandler not exported, so use duck typing
                handle(req: HttpRequest<any>) {
                    return interceptor.intercept(req, next);
                },
            }), next).handle(req);
    }
}

export function angular18894CrutchFactory(interceptors: HttpInterceptor[]) {
    return new AngularIssues18894Crutch(interceptors);
}
export const angular18894CrutchProvider = {
    // https://github.com/angular/angular/issues/18894
    provide: HTTP_INTERCEPTORS,
    multi: true,
    useFactory: angular18894CrutchFactory,
    deps: [[new SkipSelf, new Optional, new Inject(HTTP_INTERCEPTORS)]],
};


/**
 * A hack to prevent Plesk from adding a csrf header to some requests.
 */
export function applyCsrfStrategy() {
    const csrfTokenMeta = document.getElementById('forgery_protection_token');
    const urls = ['https://api.imunify360.com', 'https://sentry.cloudlinux.com'];
    const oldSend = XMLHttpRequest.prototype.send;
    XMLHttpRequest.prototype.send = function (...args) {
        let skipHeader = false;
        try {
            if (urls.find(url => (this.__zone_symbol__xhrURL as string).startsWith(url))) {
                skipHeader = true;  // this request shouldn't contain csrf header
            }
        } catch (e) { }
        if (skipHeader && csrfTokenMeta) {
            csrfTokenMeta.setAttribute('id', 'temporary-changed-for-sentry-request');
            const retVal = oldSend.apply(this, args);
            csrfTokenMeta.setAttribute('id', 'forgery_protection_token');
            return retVal;
        } else {
            return oldSend.apply(this, args);
        }
    };
}
