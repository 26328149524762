import * as tslib_1 from "tslib";
/*

This program is free software: you can redistribute it and/or modify it under
the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License,
or (at your option) any later version.


This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
See the GNU General Public License for more details.


You should have received a copy of the GNU General Public License
 along with this program.  If not, see <https://www.gnu.org/licenses/>.

Copyright © 2019 Cloud Linux Software Inc.

This software is also available under ImunifyAV commercial license,
see <https://www.imunify360.com/legal/eula>
*/
import { EventEmitter, Injector } from '@angular/core';
import { HttpClient, HttpResponse, } from '@angular/common/http';
import { Result } from '@imunify360-api/misc';
import { NotificationsService } from 'app/services/notifications';
import { XhrReporterService } from 'app/services/xhr-reporter';
import { of, throwError as _throw, timer } from 'rxjs';
import { catchError, map, retryWhen, scan, switchMap } from 'rxjs/operators';
import { UntranslatableString } from 'app/utils/translate/translate.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../services/notifications";
/**
 * @returns {string} JSON-like string with a lot of escaping when converting circular values,
 * but it is short
 */
function toString(deepness) {
    if (deepness === void 0) { deepness = 0; }
    return JSON.stringify(this, function replacer(key, value) {
        try {
            JSON.stringify(value);
            return value;
        }
        catch (e) {
            if (deepness >= 3) {
                return 'Circular';
            }
            var res_1 = {};
            Object.keys(value).forEach(function (key) {
                res_1[key] = toString.call(value[key], deepness + 1);
            });
            return res_1;
        }
    });
}
function addToString(error) {
    Object.defineProperty(error, 'toString', {
        value: toString,
        enumerable: false,
    });
}
var XHR = /** @class */ (function () {
    function XHR(http, injector, notifications) {
        this.http = http;
        this.injector = injector;
        this.notifications = notifications;
        this.loaded = new EventEmitter();
    }
    XHR.prototype._throw = function (e) {
        // for mock in test
        return _throw(e);
    };
    XHR.prototype.post = function (params, mock, notifyOnWarning, notifyOnError) {
        var _this = this;
        if (mock === void 0) { mock = function () { return null; }; }
        if (notifyOnWarning === void 0) { notifyOnWarning = true; }
        if (notifyOnError === void 0) { notifyOnError = true; }
        var startDate = 0;
        var post = of(null).pipe(switchMap(function () {
            startDate = Date.now();
            // fast attempt to call interceptors when testMode=true
            // can be broken with async interceptors and parallel requests
            // but for now all interceptors are sync, so request should not be called
            // when mock is applied
            var mocked = mock();
            if (mocked) {
                var prevHandle_1 = _this.http['handler'].backend.handle;
                _this.http['handler'].backend.handle = function () {
                    _this.http['handler'].backend.handle = prevHandle_1;
                    return mocked.pipe(switchMap(function (res) {
                        return of(new HttpResponse({
                            body: res,
                        }));
                    }));
                };
            }
            return _this.http.post('', params);
        }));
        if (ENV === 'development') {
            post = post.pipe(retryWhen(function (error) {
                return error.pipe(scan(function (acc, res) {
                    acc.res = res;
                    acc.acc += 1;
                    return acc;
                }, { acc: 0, res: null }), switchMap(function (acc) {
                    if (acc.acc < 3) {
                        return timer(2000);
                    }
                    else {
                        throw acc.res;
                    }
                }));
            }));
        }
        return post.pipe(catchError(function (err) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.showResponseErrors(err);
                throw err;
            });
        }); }), map(function (rawResponse) {
            var messages = rawResponse.messages;
            var response = {
                data: rawResponse.data,
                result: rawResponse.result,
                messages: messages
                    ? typeof messages === 'string' ? [messages] : messages
                    : [],
            };
            if (TEST) {
                XhrReporterService.report(params, response, startDate);
            }
            _this.loaded.emit();
            switch (response.result) {
                case Result.SUCCESS:
                    return response;
                case Result.WARNING:
                    response.noSentry = true;
                    if (notifyOnWarning) {
                        _this.showResponseErrors(response);
                    }
                    throw response;
                case Result.ERROR:
                    response.noSentry = true;
                    if (notifyOnError) {
                        _this.showResponseErrors(response);
                    }
                    throw response;
                default:
                    // For success cli may not return result key
                    return response;
            }
        }), catchError(function (error) {
            addToString(error);
            return _this._throw(error);
        }));
    };
    XHR.prototype.showResponseErrors = function (error) {
        var e_1, _a;
        if ('message' in error) {
            var content = (new DOMParser()).parseFromString(error.error, 'text/html')['body']
                .innerText;
            if (content === '[object Object]') {
                console.log(error);
                content = 'System error';
            }
            this.notifications.error(UntranslatableString.for(content.trim()), "notifications." + Result.ERROR);
        }
        else if ('messages' in error) {
            var license = {};
            try {
                var LicenseService = require('app/services/license').LicenseService;
                license = this.injector.get(LicenseService).license;
            }
            catch (e) {
                // license service is not available in login module
            }
            try {
                for (var _b = tslib_1.__values(error.messages), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var msg = _c.value;
                    this.notifications.error(msg, "notifications." + error.result, license);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    };
    XHR.ngInjectableDef = i0.defineInjectable({ factory: function XHR_Factory() { return new XHR(i0.inject(i1.HttpClient), i0.inject(i0.INJECTOR), i0.inject(i2.NotificationsService)); }, token: XHR, providedIn: "root" });
    return XHR;
}());
export { XHR };
